import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { breakpoint } from '@configs/utilities'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import SectionHeader from '@components/ui/typography/SectionHeader'
import TemplateCard from '@components/common/TemplateCard'
const TemplatesCardsSection = ({ intl }) => {
  const queryData = useStaticQuery(graphql`
    query {
      allTemplatesCardsJson {
        edges {
          node {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 500
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  breakpoints: [320, 768, 1380]
                )
              }
            }
            name
            title
          }
        }
      }
    }
  `)

  const data = queryData.allTemplatesCardsJson.edges
  return (
    <SectionWrapper>
      <SectionHeader
        handwriting={intl.formatMessage({ id: 'home.templates.handwriting' })}
        title={intl.formatMessage({ id: 'home.templates.title' })}
        description={intl.formatMessage({ id: 'home.templates.description' })}
        titleMaxWidth="700px"
        descriptionMaxWidth="750px"
      />
      <CardsWrapper>
        {data.map((item, index) => {
          return (
            <div key={index}>
              <TemplateCard
                image={item.node.image.childImageSharp.gatsbyImageData}
                title={intl.formatMessage({ id: item.node.title })}
                // goTo={intl.formatMessage({ id: item.node.goTo })}
              />
            </div>
          )
        })}
      </CardsWrapper>
      <AndManyMore>{intl.formatMessage({ id: 'miscellaneous.andManyMoreTemplates' })}</AndManyMore>
    </SectionWrapper>
  )
}

export default injectIntl(TemplatesCardsSection)

const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  div {
    max-width: 280px;

    @media screen and (min-width: ${breakpoint.md}) {
      max-width: 222px;
    }
    @media screen and (min-width: ${breakpoint.xl}) {
      max-width: 212px;
    }
  }
`

const AndManyMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 35px;
  text-align: center;
`
