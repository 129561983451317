import React from 'react'
import styled from 'styled-components'
import { breakpoint, color } from '@configs/utilities'
import { GatsbyImage } from 'gatsby-plugin-image'
import UseTemplateLink from '@components/common/UseTemplateLink'

const TemplateCard = ({ image, title, goTo }) => {
  return (
    <Card>
      <ImageWrapper>
        <GatsbyImage image={image} alt="customizable template card" />
      </ImageWrapper>
      <TextWrapper>
        <TemplateText>
          <p>{title}</p>
        </TemplateText>
        <UseTemplateLink />
      </TextWrapper>
    </Card>
  )
}

export default TemplateCard

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${color.grayDark};
  border-radius: 8px;
`

const ImageWrapper = styled.div`
  border-bottom: 1px solid ${color.grayDark};

  img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  row-gap: 10px;
`

const TemplateText = styled.div`
  font-weight: 500;
  font-size: 16px;

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 18px;
  }
`
