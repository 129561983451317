import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { breakpoint } from '@configs/utilities'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import SectionHeader from '@components/ui/typography/SectionHeader'
import OtherFeaturesCard from '@components/pages/home/otherFeaturesCard/OtherFeaturesCard'
const OtherFeaturesSection = ({ intl, sectionBackground }) => {
  const data = useStaticQuery(graphql`
    query {
      allOtherFeaturesCardsJson {
        edges {
          node {
            icon {
              publicURL
            }
            slug
            cardName
            title
            text
            goToPath
            linkText
            color
            headImage {
              childImageSharp {
                gatsbyImageData(width: 234, placeholder: BLURRED, formats: [AUTO, WEBP], breakpoints: [320, 768, 1380])
              }
            }
          }
        }
      }
    }
  `)
  return (
    <SectionWrapper>
      <SectionHeader
        handwriting={intl.formatMessage({ id: 'home.otherFeatures.handwriting' })}
        title={intl.formatMessage({ id: 'home.otherFeatures.title' })}
        titleMaxWidth="700px"
        descriptionMaxWidth="750px"
      />
      <CardsWrapper>
        {data.allOtherFeaturesCardsJson.edges.map((item, index) => {
          return <OtherFeaturesCard key={index} item={item} intl={intl} />
        })}
      </CardsWrapper>
    </SectionWrapper>
  )
}

export default injectIntl(OtherFeaturesSection)

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: ${breakpoint.sm}) {
    flex-direction: row;
    column-gap: 20px;
    flex-wrap: wrap;
  }

  // @media screen and (min-width: ${breakpoint.xl}) {
  //   justify-content: space-between;
  // }
`
