import React from 'react'
import styled from 'styled-components'
import { color, breakpoint } from '@configs/utilities'
import UnderlineLink from '@components/common/UnderlineLink'

const TextImageComponent = ({ reverse, color, tabIcon, tabName, title, description, image, goToPath, linkText }) => {
  return (
    <ReverseWrapper reverse={reverse}>
      <TextWrapper>
        <IconText>
          {tabIcon}
          <span style={{ color: color }}>{tabName}</span>
        </IconText>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <LearnMoreWrapper>
          <UnderlineLink to={goToPath} text={linkText} color={color} />
        </LearnMoreWrapper>
      </TextWrapper>
      <ImageWrapper>{image}</ImageWrapper>
    </ReverseWrapper>
  )
}

export default TextImageComponent

const ReverseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${color.font};
  row-gap: 10px;
  margin: 20px 0;

  @media screen and (min-width: ${breakpoint.md}) {
    flex-direction: row;
    column-gap: 5%;
    flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    column-gap: 100px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 20px;
  max-width: 500px;

  @media screen and (min-width: ${breakpoint.md}) {
    width: 50%;
    max-width: auto;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 0;
  }
`

const IconText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 5px;
  }

  span {
    font-weight: 500;
    font-size: 18px;
    //margin-right: 5px;
  }
`

const Title = styled.p`
  font-weight: 600;
  font-size: 18px;

  @media screen and (min-width: ${breakpoint.md}) {
    max-width: 300px;
    font-size: 22px;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    max-width: 380px;
    font-size: 26px;
  }
`

const Description = styled.p`
  font-weight: 300;
  font-size: 14px;

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 14px;
    max-width: 340px;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    font-size: 16px;
    max-width: 380px;
  }
`

const ImageWrapper = styled.div`
  max-width: 500px;

  @media screen and (min-width: ${breakpoint.md}) {
    width: 50%;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    max-width: 600px;
  }
`

const LearnMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
