import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { breakpoint, color } from '@configs/utilities'
import { StaticImage } from 'gatsby-plugin-image'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import SectionHeader from '@components/ui/typography/SectionHeader'
import TextImageComponent from '@components/common/TextImageComponent'
import businessGuideIcon from '@assets/images/features/business-guideIcon.svg'
import aiAssistanceIcon from '@assets/images/aiAssistance/aiAssistanceIcon.svg'
import financialPlanIcon from '@assets/images/features/financial-planIcon.svg'
import businessPlanIcon from '@assets/images/features/business-planIcon.svg'
import TestimonialFleckComponent from '@components/common/TestimonialFleckComponent'
import CapterraLogo from '@assets/images/common/testimonialFleckCapterraLogo.svg'
const FeaturesTextImageSection = ({ intl }) => {
  return (
    <SectionWrapper>
      <SectionHeader
        handwriting={intl.formatMessage({ id: 'home.features.handwriting' })}
        title={intl.formatMessage({ id: 'home.features.title' })}
        description={intl.formatMessage({ id: 'home.featuresTextImageComponent.description' })}
        titleMaxWidth="700px"
        descriptionMaxWidth="750px"
      />
      <CardsWrapper>
        <TextImageComponent
          color={color.red}
          tabIcon={
            <img
              src={businessGuideIcon}
              alt={intl.formatMessage({ id: 'home.featuresTextImageComponent.business-guide.tabName' })}
              width={20}
            />
          }
          tabName={intl.formatMessage({ id: 'home.featuresTextImageComponent.business-guide.tabName' })}
          title={intl.formatMessage({ id: 'home.featuresTextImageComponent.business-guide.tabTitle' })}
          description={intl.formatMessage({ id: 'home.featuresTextImageComponent.business-guide.tabDescription' })}
          image={
            <StaticImage
              src="../../assets/images/home/featuresTextImage/businaessGuide.webp"
              alt={intl.formatMessage({ id: 'home.featuresTextImageComponent.business-guide.tabTitle' })}
              placeholder="blurred"
            />
          }
          goToPath="/features/business-guide/"
          linkText={intl.formatMessage({ id: 'home.features.learnMore' })}
        />
        <TextImageComponent
          reverse
          color={color.ai}
          tabIcon={
            <img
              src={aiAssistanceIcon}
              alt={intl.formatMessage({ id: 'home.featuresTextImageComponent.ai-assistance.tabName' })}
              width={20}
            />
          }
          tabName={intl.formatMessage({ id: 'home.featuresTextImageComponent.ai-assistance.tabName' })}
          title={intl.formatMessage({ id: 'home.featuresTextImageComponent.ai-assistance.tabTitle' })}
          description={intl.formatMessage({ id: 'home.featuresTextImageComponent.ai-assistance.tabDescription' })}
          image={
            <StaticImage
              src="../../assets/images/home/featuresTextImage/aiAssistance.webp"
              alt={intl.formatMessage({ id: 'home.featuresTextImageComponent.ai-assistance.tabTitle' })}
              placeholder="blurred"
            />
          }
          goToPath="/features/ai-assistance/"
          linkText={intl.formatMessage({ id: 'home.features.learnMore' })}
        />
      </CardsWrapper>

      <TestimonialWrapper>
        <TestimonialFleckComponent
          text={intl.formatMessage({ id: 'miscellaneous.highlyRecommendIdeaBuddy' })}
          signature="Justin P. , Marketing Director"
          logo={
            <img
              src={CapterraLogo}
              alt={intl.formatMessage({ id: 'home.featuresTextImageComponent.business-guide.tabTitle' })}
            />
          }
        />
      </TestimonialWrapper>

      <CardsWrapper>
        <TextImageComponent
          color={color.financialPlan}
          tabIcon={
            <img
              src={financialPlanIcon}
              alt={intl.formatMessage({ id: 'home.featuresTextImageComponent.financial-plan.tabName' })}
              width={20}
            />
          }
          tabName={intl.formatMessage({ id: 'home.featuresTextImageComponent.financial-plan.tabName' })}
          title={intl.formatMessage({ id: 'home.featuresTextImageComponent.financial-plan.tabTitle' })}
          description={intl.formatMessage({ id: 'home.featuresTextImageComponent.financial-plan.tabDescription' })}
          image={
            <StaticImage
              src="../../assets/images/home/featuresTextImage/financialPlan.webp"
              alt={intl.formatMessage({ id: 'home.featuresTextImageComponent.financial-plan.tabTitle' })}
              placeholder="blurred"
            />
          }
          goToPath="/features/financial-plan/"
          linkText={intl.formatMessage({ id: 'home.features.learnMore' })}
        />
        <TextImageComponent
          reverse
          color={color.businessPlan}
          tabIcon={
            <img
              src={businessPlanIcon}
              alt={intl.formatMessage({ id: 'home.featuresTextImageComponent.business-plan-builder.tabName' })}
              width={20}
            />
          }
          tabName={intl.formatMessage({ id: 'home.featuresTextImageComponent.business-plan-builder.tabName' })}
          title={intl.formatMessage({ id: 'home.featuresTextImageComponent.business-plan-builder.tabTitle' })}
          description={intl.formatMessage({
            id: 'home.featuresTextImageComponent.business-plan-builder.tabDescription',
          })}
          image={
            <StaticImage
              src="../../assets/images/home/featuresTextImage/businessPlanBuilder.webp"
              alt={intl.formatMessage({ id: 'home.featuresTextImageComponent.business-plan-builder.tabTitle' })}
              placeholder="blurred"
            />
          }
          goToPath="/features/business-plan/"
          linkText={intl.formatMessage({ id: 'home.features.learnMore' })}
        />
      </CardsWrapper>
    </SectionWrapper>
  )
}

export default injectIntl(FeaturesTextImageSection)

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${breakpoint.sm}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const TestimonialWrapper = styled.div`
  max-width: 1260px;
  margin: 0 auto;

  @media screen and (min-width: ${breakpoint.md}) {
    margin: 70px auto;
  }

  @media screen and (min-width: ${breakpoint.lg}) {
    padding: 0 5%;
  }

  @media screen and (min-width: ${breakpoint.xxl}) {
    padding: 0 90px;
  }
`
